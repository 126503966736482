header {
  position: relative;
  text-align: center;
  color: #fff;
  padding: 40px 10px 10px 10px;
  overflow: hidden;
  height: 450px;
  max-height: 50%;
  border-radius: 15px;
  > h1 {
    margin: 0;
  }
  > img {
    position: absolute;
    top: 0;
    right: -75px;
    z-index: -1;
    width: auto;
    height: 110%;
  }
}
