section.tutorials {
  > img {
    width: 111%;
    margin-left: -20px;
    outline: 0;
  }
  > div.comment {
    text-align: left;
    margin-top: 25px;
    > p {
      margin: 0;
    }
  }
  > a {
    display: block;
    margin-top: 25px;
  }
}
