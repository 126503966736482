section.contact {
  > img {
    width: 110%;
    margin-left: -25px;
  }
  > a {
    display: block;
    width: 50%;
    background-color: var(--link);
    color: var(--white);
    padding: 10px 25px;
    font-size: 1.25em;
    border-radius: 10px;
    margin: 0px auto 10px auto;
  }
}
