:root {
  --white: #fff;
  --black: #000;
  --gray: #aaa;
  --link: #06c;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Ubuntu', sans-serif;
}

body {
  background-color: #f5f5f7;
}

.eyebrow {
  color: var(--gray);
  margin-top: 5px;
  margin-bottom: 25px;
}

.header {
  margin: 0;
}

section {
  padding: 25px 15px;
  background-color: var(--white);
  margin: 20px 0;
  border-radius: 15px;
  overflow: hidden;
  box-sizing: border-box;
  text-align: center;
}

a {
  text-decoration: none;
  color: var(--link);
}
