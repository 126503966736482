section.experience {
  text-align: center;
  > h2 {
    color: #444;
  }
  > img {
    margin-left: -15px;
    margin-bottom: -140px;
  }
}
